
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { reactive } from "vue";

export default defineComponent({
  data() {
    return {
      hideBackground: false,
      dataTr: [
        { id: 1, t1: "数字婚嫁", ent1: "Marriage" },
        { id: 2, t1: "数字业财税", ent1: "Fnance and taxation" },
        { id: 3, t1: "数字零售", ent1: "Retail" },
        { id: 4, t1: "数字办公", ent1: "Official" },
        { id: 5, t1: "数字权益", ent1: "Rights and interests" },
        { id: 6, t1: "云产品", ent1: "Cloud" },
        { id: 7, t1: "数字服务", ent1: "Service" },
      ],
    };
  },
  created() {
    // 在组件创建时添加路由更新守卫
    onBeforeRouteUpdate((to, from, next) => {
      // 检查要访问的路由路径是否匹配您想要隐藏背景图像的路径
      if (to.path === "/activityinfo/activityinfo1") {
        this.hideBackground = true;
      } else {
        this.hideBackground = false;
      }
      next();
    });
  },
  setup(props, context) {
    const navIndex = ref(0);
    const router = useRouter();
    const state = reactive({
      navList: [
        {
          value: "home",
          label: "  HOME  ",
          enLabel: "",
          path: "/",
          image: {
            src: "../../../public/img/logo/nav_bo.png",
            alt: "Home Icon",
          },
        },
        {
          value: "Corporate Governance",
          label: "Corporate Governance",
          enLabel: "",
          path: "",
        },
        {
          value: "Press Releases",
          label: "Press Releases",
          enLabel: "",
          path: "/pressreleases/press_releases",
        },
        {
          value: "Investor Kits",
          label: "Investor Kits",
          enLabel: "",
          path: "",
        },
        {
          value: "Financial Information",
          label: "Financial Information",
          enLabel: "",
          path: "",
        },
        {
          value: "Stock Info",
          label: "Stock Info",
          enLabel: "",
          path: "",
        },
        {
          value: "Shareholder Services",
          label: "Shareholder Services",
          enLabel: "",
          path: "",
        },
      ],
      // navIndex: 0,
      popupVisible: [false, false, false, false, false, false],
    });

    onMounted(() => {
      const _index = parseInt(sessionStorage.getItem("navIndex")!);
      console.log(_index, "我是获取的");
      navIndex.value = _index ? _index : 0;
    });

    //点击logo跳转首页
    const chooseNavLogo = () => {
        window.open("https://www.dev-jd-a.91fd.tech/");
      // sessionStorage.setItem("navIndex", String(index));
      // // 子页面时 导航栏跳转
      // if (router.currentRoute.value.path !== "/") {
      //   router.push("/");
      // }
      // window.scrollTo(0, 0);
      // navIndex.value = index;
      // context.emit("getNav", index);
    };

    const chooseNav = (index: number) => {
      sessionStorage.setItem("navIndex", String(index));
      // 子页面时 导航栏跳转
      // if (router.currentRoute.value.path !== "/") {
      //   router.push("/");
      // }popup3
      console.log(index, "我是第一次");
      window.scrollTo(0, 0);
      navIndex.value = index;
      context.emit("getNav", index);
    };

    const showPopup = (index: number) => {
      // 根据当前悬停的菜单项的索引值来显示相应的弹窗
      if (index == 1) {
        const popup = document.querySelector(`#popup-${index}`);
        if (popup) {
          popup.classList.add("show");
        }
      }
      if (index == 3) {
        const popup3 = document.querySelector(`#popup3-${index}`);
        if (popup3) {
          popup3.classList.add("show");
        }
      }
      if (index == 4) {
        const popup4 = document.querySelector(`#popup4-${index}`);
        if (popup4) {
          popup4.classList.add("show");
        }
      }
      if (index == 6) {
        const popup6 = document.querySelector(`#popup6-${index}`);
        if (popup6) {
          popup6.classList.add("show");
        }
      }
      state.popupVisible.splice(index, 1, true);
    };

    const hidePopup = (index: number) => {
      // 根据当前悬停的菜单项的索引值来隐藏相应的弹窗
      const popup = document.querySelector(`#popup-${index}`);
      if (popup) {
        popup.classList.remove("show");
      }
      state.popupVisible.splice(index, 1, false);
    };

    const cancelHidePopup = () => {
      // 取消隐藏弹窗
      // 如果需要在弹窗中放置交互元素，可以使用这个方法来防止鼠标离开弹窗时立即隐藏弹窗
    };

    const toTop = () => {
      window.scrollTo(0, 0);
    };

    return {
      ...state,
      showPopup,
      hidePopup,
      cancelHidePopup,
      // navList,
      chooseNavLogo,
      navIndex,
      chooseNav,
      toTop,
    };
  },
});
